@import '~@/common.scss';
.cmp-award-progress {
  --progress: 0%; //进度变量

  width: 100%;
  padding: 0 px2rem(15px);
  box-sizing: border-box;
  position: relative;
  .cmp-gift-box {
    width: px2rem(30px);
    height: px2rem(30px);
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-progress {
    position: relative;
    height: px2rem(5px);
    .unactivate,
    .activate {
      width: 100%;
      height: 100%;
      border-radius: px2rem(999999px);
      position: absolute;
      left: 0;
      top: 0;
    }
    .unactivate {
      background-color: rgba(217, 217, 217, 1);
    }
    .activate {
      width: var(--progress);
      background: linear-gradient(90deg, #1ff5a9 0%, #00e0d5 100%);
      transition: all 0.3s linear;
    }
  }
  &-nums {
    position: relative;
    height: px2rem(16px);
    margin-top: px2rem(13px);
    span {
      position: absolute;
      color: #afafaf;

      &:nth-child(1) {
        left: 0;
      }
      &:nth-child(2) {
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-child(3) {
        right: px2rem(6px);
      }
    }
    & > .activate {
      color: #10ebbe;
    }
  }
  &-gift {
    width: 100%;
    height: 100%;
    position: absolute;
    .cmp-gift-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &:first-child {
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:last-child {
        right: 0;
      }
    }
  }
  &-current {
    position: relative;
    height: px2rem(31px);
    box-sizing: border-box;
    // margin: 0 px2rem(15px);
    &__content {
      position: absolute;
      background: #10ebbe;
      padding: 0 px2rem(8px);
      font-size: px2rem(10px);
      font-weight: 500;
      color: #ffffff;
      line-height: px2rem(14px);
      border-radius: px2rem(999999px);
      z-index: 2;
      top: 0;
      left: var(--progress);
      // transform: translateX(-50%);
      white-space: nowrap;
      transition: all 0.3s linear;

      &::after {
        content: '';
        position: absolute;
        border: px2rem(3px) solid transparent;
        border-top-color: #10ebbe;
        bottom: px2rem(-4px);
        z-index: 1;
        left: 0;
        transform: rotate(25deg);
      }
    }
  }
  .current-transform {
    & > div {
      transform: translateX(-50%);
      &::after {
        left: unset;
        right: px2rem(12px);
        bottom: px2rem(-6px);
        transform: rotate(0deg);
      }
    }
  }
  .current-transform2 {
    margin: 0 px2rem(15px);
    & > div {
      transform: translateX(-50%);
      &::after {
        left: 50%;
        transform: translateX(-50%);
        bottom: px2rem(-6px);
      }
    }
  }
}
.mirror .cmp-award-progress {
  direction: rtl;
  &-progress {
    .unactivate,
    .activate {
      left: unset;
      right: 0;
    }
  }
  &-gift {
    .cmp-gift-box {
      &:last-child {
        right: unset;
        left: 0;
      }
    }
  }
  &-nums {
    span {
      &:nth-child(1) {
        left: unset;
        right: 0;
      }
      &:nth-child(3) {
        right: unset;
        left: px2rem(6px);
      }
    }
  }
  &-current {
    &__content {
      left: unset;
      right: var(--progress);
      &::after {
        left: unset;
        right: 0;
        transform: rotate(-25deg);
      }
    }
  }
  .current-transform {
    & > div {
      transform: translateX(50%);
      &::after {
        left: px2rem(12px);
        right: unset;
        transform: rotate(0deg);
      }
    }
  }
  .current-transform2 {
    & > div {
      &::after {
        left: unset;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}
