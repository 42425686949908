@import '../../../common.scss';

.country {
  padding-left: px2rem(15px);
  height: px2rem(509px);
  width: px2rem(375px);
  background-color: #fff;
  border-radius: px2rem(12px) px2rem(12px) 0 0;
  box-sizing: border-box;
  &-list {
    height: px2rem(405px);
    overflow-y: auto;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px2rem(15px) 0;
    border-bottom: #eee px2rem(1px) solid;
    margin-right: px2rem(31px);
    &-left {
      display: flex;
      align-items: center;
      span {
        margin-left: px2rem(9px);
      }
      img {
        width: px2rem(30px);
        height: px2rem(20px);
      }
    }
    &-select {
      height: px2rem(17px);
      width: px2rem(17px);
    }
  }
  &-title {
    font-size: px2rem(18px);
    font-weight: 400;
    color: #1a1a1a;
    line-height: px2rem(25px);
    display: flex;
    justify-content: center;
    padding: px2rem(20px) 0;
    // box-sizing: border-box;
  }
  &-option {
    font-size: px2rem(13px);
    font-weight: 400;
    color: #333333;
    line-height: px2rem(19px);
  }
}

.mirror {
  & .country {
    padding-right: px2rem(15px);
    &-option {
      text-align: right;
    }
    &-item {
      flex-direction: row-reverse;
      margin-right: unset;
      &-left {
        flex-direction: row-reverse;
        span {
          margin-right: px2rem(9px);
        }
      }
    }
  }
}

.country-popup .adm-popup-body {
  background-color: transparent !important;
}
