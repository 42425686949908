@import '../../common.scss';

.pg-recharge {
  width: 100%;
  padding-bottom: px2rem(58px);
  background-color: #fafafa;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  .cmp-pane {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: px2rem(20px) px2rem(15px) 0;

    &__title {
      height: px2rem(26px);
      font-size: px2rem(12px);
      font-weight: 400;
      color: #999999;
      line-height: px2rem(26px);
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  &-lang {
    position: absolute;
    right: px2rem(15px);
    top: px2rem(20px);
    height: px2rem(26px);
    width: px2rem(72px);
    box-sizing: border-box;
    overflow: hidden;
    transition: height 300ms linear;
    &-current {
      box-sizing: border-box;
      background: #fafafa;
      border-radius: px2rem(4px);
      height: px2rem(26px);
      border: px2rem(1px) solid #e8e8e8;
      width: px2rem(72px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: px2rem(5px) px2rem(10px);
      &-text {
        font-size: px2rem(11px);
        color: #505050;
        line-height: px2rem(15px);
        font-weight: 400;
      }
      &-icon {
        width: px2rem(9px);
        height: px2rem(5px);
        margin: 0 px2rem(5px);
      }
    }
    &.display {
      box-sizing: border-box;
      border: px2rem(1px) solid #e8e8e8;
      border-radius: px2rem(4px);
      height: px2rem(78px);
    }
    &.display &-current {
      border: unset;
    }
    &-options {
      text-align: center;
      height: px2rem(26px);
      font-size: px2rem(11px);
      color: #505050;
      line-height: px2rem(26px);
      font-weight: 400;
      background-color: #fafafa;
    }
  }
  & .sticky-header {
    position: sticky;
    background-color: #fafafa;
    top: 0;
    z-index: 10;
  }
  &__input,
  &__pay {
    margin: px2rem(20px) px2rem(15px) 0;
    width: px2rem(345px);
    height: px2rem(55px);
    border-radius: px2rem(6px);
    display: flex;
    align-items: center;
  }
  .first .cmp-pane {
    &__title {
      color: #999999;
      font-size: px2rem(12px);
    }
  }
  &__input {
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    padding: 0 px2rem(15px);
    box-sizing: border-box;
    margin-bottom: px2rem(10px);
    input {
      background-color: transparent;
      border: none;
      flex: 1;
    }
  }
  &__user {
    flex: 1;
    display: flex;
    align-items: center;
    &-avatar {
      width: px2rem(35px);
      height: px2rem(35px);
      border-radius: 50%;
    }
    &-name {
      font-size: px2rem(13px);
      color: #000000;
      margin-left: px2rem(10px);
    }
  }
  &__confirm,
  &__cancel {
    width: px2rem(73px);
    height: px2rem(30px);
    color: white;
    display: flex;
    align-items: center;
    border-radius: px2rem(30px);
    justify-content: center;
  }
  &__confirm {
    background: linear-gradient(to right, #1ff5a9, #00e0d5);
  }
  &__cancel {
    background-color: #cecece;
  }

  &-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    &-tab {
      width: px2rem(57px);
      height: px2rem(23px);
      margin: px2rem(20px) 0;
      font-weight: 500;
      color: #333;
      font-size: px2rem(16px);
      line-height: px2rem(23px);
      position: relative;
      text-align: center;
      &:first-child {
        margin-right: px2rem(60px);
      }
    }
    &-line {
      width: px2rem(16px);
      height: px2rem(2px);
      background: #10ebbe;
      border-radius: px2rem(1px);
      position: absolute;
      left: px2rem(20px);
    }
    &-noselect {
      span {
        color: #afafaf !important;
        display: inline-block;
      }
    }
  }

  &-country {
    display: flex;
    align-items: center;
    margin-bottom: px2rem(15px);
    &-icon {
      width: px2rem(12px);
      height: px2rem(14px);
      margin: 0 px2rem(6px) 0 px2rem(15px);
    }
    span {
      height: px2rem(17px);
      font-size: px2rem(12px);
      font-weight: 500;
      color: #676767;
      line-height: px2rem(17px);
    }
  }

  &__bottom {
    position: fixed;
    z-index: 10;
    text-align: center;
    bottom: px2rem(20px);
    left: 0;
    width: 100%;
  }
  &__btn {
    border-radius: px2rem(20px);
    margin: 0 auto;
    background: linear-gradient(to right, #1ff5a9, #00e0d5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: px2rem(14px);
    color: white;
    width: px2rem(345px);
    height: px2rem(38px);
  }
}
.mirror {
  .pg-recharge {
    &__input {
      flex-direction: row-reverse;
      input {
        text-align: right;
      }
    }
    &__user {
      flex-direction: row-reverse;
      &-name {
        margin-left: unset;
        margin-right: px2rem(10px);
      }
    }
    &-country {
      flex-direction: row-reverse;
      &-icon {
        width: px2rem(12px);
        height: px2rem(14px);
        margin: 0 px2rem(15px) 0 px2rem(6px);
      }
    }
  }
}
.pc {
  .pg-recharge {
    width: px2rem(375px);
    margin: 0 auto;
    min-height: 100vh;
  }
  .country {
    margin: 0 auto;
  }
}
