@import '../../../common.scss';

.pg-recharge .reseller {
  height: px2rem(100px);
  &-tip {
    box-sizing: border-box;
    width: px2rem(345px);
    height: px2rem(153px);
    background: #f0f0f0;
    margin: 0 px2rem(15px);
    &-top {
      width: px2rem(315px);
      text-align: left;
      font-size: px2rem(12px);
      padding: px2rem(15px) px2rem(15px) 0;
    }
  }
  &-line {
    width: px2rem(375px);
    height: px2rem(2px);
    margin-top: px2rem(20px);
    margin-bottom: px2rem(20px);
    background: #eeeeee;
  }
  &-list {
    width: px2rem(345px);
    margin: px2rem(10px) px2rem(15px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      align-items: center;
      &-avatar {
        width: px2rem(55px);
        height: px2rem(55px);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: px2rem(55px);
          height: px2rem(55px);
        }
      }
      &-info {
        font-size: px2rem(13px);
        margin-left: px2rem(10px);
        &-copy {
          display: flex;
          align-items: center;
          img {
            width: px2rem(20px);
            height: px2rem(20px);
            margin: 0 px2rem(2px);
          }
          span {
            font-size: px2rem(11px);
            color: #b7b7b7;
            line-height: px2rem(13px);
          }
        }
      }
    }
    &-right {
      font-size: px2rem(12px);
      color: #000000;
      line-height: px2rem(14px);
      display: flex;
      align-items: center;
      div {
        margin-left: px2rem(4px);
      }
      img {
        width: px2rem(8px);
        height: px2rem(10px);
      }
    }
  }
}

.mirror {
  .pg-recharge {
    & .reseller {
      &-tip {
        &-top {
          text-align: right;
        }
      }
      &-list {
        flex-direction: row-reverse;
        &-left {
          flex-direction: row-reverse;
          &-info {
            div {
              text-align: right;
              margin-right: px2rem(10px);
            }
            &-copy {
              flex-direction: row-reverse;
            }
          }
        }
        &-right {
          &-count {
            margin-right: px2rem(4px);
          }
        }
      }
    }
  }
}
