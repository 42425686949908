@import '~@/common.scss';
.cmp-log-in {
  position: absolute;
  inset: 0;
  background-color: rgba(27, 25, 29, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(px2rem(6px));
  align-items: flex-end;
  &__dialog {
    z-index: 10000000;
    width: 100%;
    height: px2rem(275px);
    background: linear-gradient(to right, #1ff5a9, #00e0d5);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: px2rem(5px);
    border-top-right-radius: px2rem(5px);
  }
  &__logo {
    width: px2rem(50px);
    height: px2rem(50px);
    margin-bottom: px2rem(20px);
  }
  &__app,
  &__password {
    all: unset;
    background: rgba(255, 255, 255, 0.6);
    font-size: px2rem(14px);
    border-radius: px2rem(15px);
    border: px2rem(1px) solid #fff;
    text-indent: px2rem(10px);
  }
  &__app,
  &__password,
  &__btn {
    width: px2rem(320px);
    height: px2rem(30px);
    margin-bottom: px2rem(20px);
  }

  &__btn {
    background: none;
    border: none;
    background: url('~@/images/log-btn.webp') no-repeat center/cover;
  }
  .gray {
    background: url('~@/images/log-btn-gray.webp') no-repeat center/cover;
  }
}
