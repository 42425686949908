@import '../../../common.scss';

.pg-recharge-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: px2rem(163px);
    height: px2rem(146px);
  }
  span {
    margin-top: px2rem(21px);
    color: #b7b7b7;
    font-size: px2rem(13px);
  }
}

.mirror {
  .pg-recharge-empty {
    img {
      transform: rotateY(180deg);
    }
  }
}
