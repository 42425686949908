@import '~@/common.scss';

.methods {
  &-body {
    &:not(:first-child) {
      margin-top: px2rem(10px);
    }
    &-item {
      width: px2rem(345px);
      height: px2rem(46px);
      background: #f3f3f3;
      border-radius: px2rem(6px);
      margin-left: px2rem(15px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        & .pay-type-icon {
          width: px2rem(35px);
          height: px2rem(35px);
          margin-left: px2rem(15px);
        }
        & .pay-type-name {
          display: flex;
          align-items: center;
        }
      }
      span {
        height: px2rem(16px);
        font-size: px2rem(13px);
        color: #000;
        line-height: px2rem(16px);
      }
      &-icon {
        margin: 0 px2rem(15px);
        width: px2rem(14px);
        height: px2rem(14px);
        &.right {
          width: px2rem(11px);
          height: px2rem(8px);
        }
      }
    }
    &-title {
      font-size: px2rem(16px);
      font-weight: 700;
      color: #333;
      line-height: px2rem(23px);
      margin: px2rem(20px) 0 px2rem(10px) px2rem(15px);
    }
    &-list {
      margin-left: px2rem(15px);
      margin-bottom: px2rem(20px);
      &__coin {
        margin-top: px2rem(15px);
        display: grid;
        width: 100%;
        grid-template-columns: px2rem(163px) px2rem(163px);
        grid-gap: px2rem(25px) px2rem(15px);
        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: px2rem(80px);
          width: px2rem(165px);
          border-radius: px2rem(10px);
          border: solid px2rem(1px) #e8e8e8;
          background-color: white;
          position: relative;
          &.selected {
            border-color: #10ebbe;
            background-color: #e1fff9;
          }
          &-return {
            display: flex;
            justify-content: center;
            height: px2rem(22px);
            line-height: px2rem(22px);
            width: px2rem(44px);
            color: #fff;
            position: absolute;
            right: 0;
            top: 0;
            background-image: url(../images/return.png);
            background-repeat: no-repeat;
            background-size: cover;
            span {
              font-size: px2rem(10px);
              font-weight: bold;
            }
          }
          &-up {
            display: flex;
            align-items: center;
            margin-top: px2rem(15px);
            img {
              width: px2rem(18px);
              height: px2rem(18px);
            }
            span {
              line-height: px2rem(18px);
              font-size: px2rem(15px);
              color: #ffaf00;
              font-weight: bold;
              margin: 0 px2rem(7px);
            }
          }
          &-inventory {
            position: absolute;
            bottom: px2rem(-20px);
            left: 50%;
            transform: translateX(-50%);
            font-size: px2rem(12px);
            color: #b7b7b7;
            white-space: nowrap;
          }
          &.selected {
            border-color: #10ebbe;
            background-color: #e1fff9;
            & .methods-item-list__coin-item-up span {
              color: #000;
            }
          }
        }

        &-price {
          font-size: px2rem(12px);
          color: #b7b7b7;
          margin-top: px2rem(15px);
          font-weight: 400;
        }
        .limited-discount {
          border: px2rem(1px) solid rgba(244, 212, 147, 1);
          background-color: rgba(255, 238, 215, 1);
          .methods-body-list__coin-item-up span {
            color: #000;
          }
          .methods-body-list__coin-item-inventory {
            color: #333333;
          }
          .methods-body-list__coin-item-return {
            background-image: url(../images/limited-discount-return.png);
          }
        }
        .limited-discount.selected {
          background: url(../images/limited-discount-selected.png) no-repeat
            center/cover;
          .methods-body-list__coin-item-up span {
            color: #fff;
          }
          .methods-body-list__coin-price span {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.mirror {
  & .methods {
    &-body {
      &-item {
        &-content {
          flex-direction: row-reverse;
          & .pay-type-name {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
          }

          & .pay-type-icon {
            margin-right: px2rem(15px);
          }
        }
      }
      &-title {
        text-align: right;
        margin: px2rem(20px) px2rem(15px) px2rem(10px) 0;
      }
      &-list {
        margin-right: px2rem(15px);
        &__coin {
          direction: rtl;
          &-item {
            position: relative;
            // &-return {
            //   position: absolute;
            //   left: 0 !important;
            //   top: 0;
            // }
            &-up {
              direction: ltr;
            }
          }
        }
      }
    }
  }
}
