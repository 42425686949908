body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide {
  display: none !important;
}

.pg {
  min-height: 100vh;
}

.pg.loading {
  width: 100vw;
  overflow: hidden;
}

/* .pc-adaptation {
  padding: 0 500px;
  box-sizing: border-box;
} */
/* .mirror {
  direction: rtl;
} */
